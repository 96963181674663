const routes = [
  {
    path: ["/", "/home"],
    exact: true,
    component: "Home",
  },{
    path: ["/imprint"],
    exact: true,
    component: "Imprint",
  },{
    path: ["/policy"],
    exact: true,
    component: "Policy",
  },
];

export default routes;
