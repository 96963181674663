import { SvgIconProps } from "../types";
import {LogoContainer} from "../../components/Header/styles";

export const SvgIcon = ({ src, width, height }: SvgIconProps) => (
  <img src={`/img/svg/${src}`} alt={src} width={width} height={height} />
);

export const ImagesBox = ({ src, width, height }: SvgIconProps) => (
  <img src={`/img/images/${src}`} alt={src} width={width} height={height} />
);

export const Logo = ({ src, width, height }: SvgIconProps) => (
    <LogoContainer to="/" aria-label="homepage">
        <img src={`/img/svg/logo.svg`} alt={src} width={width} height={height} />
    </LogoContainer>
);
