import { Row, Col } from "antd";
import { withTranslation } from "react-i18next";
import {ImagesBox, Logo, SvgIcon} from "../../common/SvgIcon";
import Container from "../../common/Container";

import i18n from "i18next";
import {
  FooterSection,
  Title,
  NavLink,
  Extra,
  LogoContainer,
  Para,
  Large,
  Chat,
  Empty,
  FooterContainer,
  Language,
  Label,
  LanguageSwitch,
  LanguageSwitchContainer,
} from "./styles";

interface SocialLinkProps {
  href: string;
  src: string;
}

const Footer = ({ t }: any) => {
  const handleChange = (language: string) => {
    i18n.changeLanguage(language);
  };

  const SocialLink = ({ href, src }: SocialLinkProps) => {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        key={src}
        aria-label={src}
      >
        <SvgIcon src={src} width="25px" height="25px" />
      </a>
    );
  };

  return (
    <>
      <FooterSection>
        <Container>
          <Row justify="space-between">
            <Col lg={8} md={8} sm={12} xs={12}>
              <Language>{t("Links")}</Language>

              <Large left="true" to="/">
                {t("About")}
              </Large>
              <Large left="true" to="/policy">
                {t("Policy")}
              </Large>
              <Large left="true" to="/imprint">
                {t("Imprint")}
              </Large>
            </Col>
            <Col lg={10} md={10} sm={12} xs={12}>
              <Language>{t("Address")}</Language>
              <Para>{process.env.REACT_APP_COMPANY_STREET_NO}</Para>
              <Para>{process.env.REACT_APP_COMPANY_ZIP} {process.env.REACT_APP_COMPANY_CITY}</Para>
              <Para>{t(process.env.REACT_APP_COMPANY_COUNTRY)}</Para>
            </Col>
            <Col lg={6} md={6} sm={12} xs={12}>
              <Label htmlFor="select-lang">{t("Language")}</Label>
              <LanguageSwitchContainer>
                <LanguageSwitch onClick={() => handleChange("de")}>
                  <ImagesBox
                    src="icons8-deutschland-30.png"
                    aria-label="homepage"
                    width="30px"
                    height="30px"
                  />
                </LanguageSwitch>
                <LanguageSwitch onClick={() => handleChange("tr")}>
                  <ImagesBox
                      src="icons8-turkei-30.png"
                      aria-label="homepage"
                      width="30px"
                      height="30px"
                  />
                </LanguageSwitch>
                <LanguageSwitch onClick={() => handleChange("en")}>
                  <ImagesBox
                      src="icons8-großbritannien-30.png"
                      aria-label="homepage"
                      width="30px"
                      height="30px"
                  />
                </LanguageSwitch>
              </LanguageSwitchContainer>
            </Col>
          </Row>
          <Row
              justify="center"
              gutter={32}
              align="middle"
              style={{ paddingTop: "3rem" }}
          >
            <Col lg={4} md={4} sm={6} xs={8}>
              <Logo width={'100%'} height={'100%'} />
            </Col>
            {/*<Col xs={'auto'}>
              <span>2022</span>
            </Col>*/}
            {/*<Col xs={'auto'}>
              <SocialLink
                  href="https://twitter.com/Adrinlolx"
                  src="twitter.svg"
              />
            </Col>
            <Col xs={'auto'}>
              <SocialLink
                  href="https://twitter.com/Adrinlolx"
                  src="twitter.svg"
              />
            </Col>*/}
          </Row>
        </Container>
      </FooterSection>
    </>
  );
};

export default withTranslation()(Footer);
